import React from "react";
import LazyLoad from "react-lazyload";
import { graphql } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import "./whitebox.scss";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Section } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyWhiteboxPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "ホワイトボックス診断",
          description:
            "世の中で広く提供されるセキュリティ診断はブラックボックス診断と呼ばれ、外部から得られる情報だけを用いて攻撃を試みるものです。一方、ソースコードやシステムの設計、仕様書などを提供していただき、それらの網羅的な分析を通して実施する診断をホワイトボックス診断と呼びます。",
        }}
      >
        <div className="Whitebox AssessmentCommonPage" id="Whitebox">
          <LazyLoad>
            <div className="AssessmentCommonTop WhiteboxTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>ホワイトボックス診断</h1>
                    <p>
                      世の中で広く提供されるセキュリティ診断は
                      <b>ブラックボックス診断</b>
                      と呼ばれ、外部から得られる情報だけを用いて攻撃を試みるものです。一方、ソースコードやシステムの設計、仕様書などを提供していただき、それらの網羅的な分析を通して実施する診断を
                      <b>ホワイトボックス診断</b>と呼びます。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="ホワイトボックス診断" />

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    <span>ブラックボックス診断と</span>
                    <span>ホワイトボックス診断</span>
                    <span>の違い</span>
                  </h2>
                </DocumentStyle>
                <img
                  src={data?.description?.publicURL || ""}
                  className="Whitebox_DescriptionImage"
                  alt=""
                />
                <DocumentStyle>
                  <h3>ブラックボックス診断</h3>
                  <p>
                    {`セキュリティ診断（脆弱性診断）の一般的な診断手法です。攻撃者の立場を再現し、外部から得られる情報だけを用いて攻撃を試みます。システムに対して脆弱性を引き起こす可能性のある入力を与え、出力結果を見て脆弱性の有無を判断します。
                    
                    ホワイトボックス診断と比較した場合、診断工数が少なくなることが多いため、実施料金も安価になることが多くなっています。`}
                  </p>
                  <h3>ホワイトボックス診断</h3>
                  <p>
                    {`ソースコードやシステムの仕様書などの内部情報を解析することで、脆弱性の有無を判断する診断手法です。Flatt Securityのホワイトボックス診断では、ブラックボックス診断と組み合わせる形で提供します。
                    
                    ブラックボックス診断と比較した場合、診断工数が大きくなることから、実施料金も高額になることが多いですが、設計や仕様、設定、ソースコードまで包括的にチェックすることが可能です。（ご利用には、診断対象となるシステムのソースコード・設計書・仕様書などのご提供が必要です。）`}
                  </p>
                </DocumentStyle>
                <div className="Whitebox_FlattStyle">
                  <DocumentStyle>
                    <h3>Flatt Securityの標準のセキュリティ診断</h3>
                    <p>
                      Flatt
                      Securityは、独自の診断スタイルを採用しています。ブラックボックス診断においても、無料でソースコード診断（部分的なホワイトボックス診断の選択的な実施）を付帯します。これにより、ブラックボックス診断に存在する網羅性・効率性の課題を、お客様の金銭的負担を増やすことなく改善しています。
                      <br />
                      <br />
                      詳細は
                      <a href="https://blog.flatt.tech/entry/2023_assessment_new_policy">
                        弊社技術ブログ
                      </a>
                      をご覧ください。
                    </p>
                  </DocumentStyle>
                </div>
              </Common2PaneSectionLayout>
            </Section>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>ホワイトボックス診断のご利用ケース</h2>
                  <h3>
                    金融系のサービスなど、設計や仕様、設定、ソースコードレベルで網羅的にリスクを洗い出す優先度が高い場合
                  </h3>
                  <p>
                    当てはまる場合、通常のWebアプリケーションであってもホワイトボックス診断の実施を推奨します。
                  </p>
                  <h3>特定のモダンな技術スタックを用いている場合</h3>
                  <p>
                    昨今はGraphQLを用いたAPI構成や、サーバーレスアーキテクチャを実現するAWS
                    Lambda及びGoogle CloudのCloud
                    Functionsなどのモダンな技術スタックを用いたアプリケーションの開発が人気です。一方で、これらのアプリケーションはブラックボックス診断では効率的なセキュリティ診断を行うことはできないケースがあり、ホワイトボックス診断の実施を推奨しています。
                    以下では、ホワイトボックス診断の実施を推奨する技術スタックの例を紹介します。
                  </p>
                  <h4>
                    Case1:
                    Firebaseをバックエンドに用いたサーバレス構成のアプリケーション
                  </h4>
                  <img
                    src={data?.wbCase1?.publicURL || ""}
                    alt="Firebaseをバックエンドに用いたサーバレス構成の概念図"
                  />
                  <h5>通常の診断における課題</h5>
                  <p>
                    Firebase
                    を用いたアプリケーションのセキュリティを確保するためには、
                    Firestore や Firebase Storage
                    へのアクセス権限を規定するセキュリティルールを正しく定義するとともに、
                    Cloud Functions の実装内容や Firebase Authentication
                    における認証のポリシー、 Firebase Hosting
                    へのデプロイに用いる構成情報といった事項がセキュアに保たれているかを確認する必要があります。
                    そのため、Firebase
                    を用いたアプリケーションの診断を実施するにあたっては、
                    セキュリティルールをはじめとする Firebase
                    固有のコンフィグレーションをホワイトボックス的に精査する必要があります。
                  </p>
                  <h5>提供をご依頼するデータ</h5>
                  <p>
                    Firebase
                    の診断を実施する際には、診断対象のサービスに応じてデータの提供をご依頼します。
                    例えば Firestore を用いたサービスを診断する場合、 Firestore
                    に設定されているセキュリティルールや、格納されているデータのスキーマ定義等をご提供いただきます。その内容を精査することで、要件上アクセスが認められないデータへのアクセスが実際に禁止されているか、不正なデータを
                    Firestore
                    に格納させることができないかといった観点に沿って診断を行います。
                  </p>
                  <h5>類似ケース</h5>
                  <p>
                    Flatt Security
                    では、サーバレス構成のアプリケーションを実現可能なその他のクラウドサービス
                    (AWS や Google Cloud など)
                    の診断にも対応しております。対応可能な環境や、提供が必要なデータについてはお気軽にお問い合わせください。
                  </p>
                  <h4>Case 2: GraphQL を用いた Web API</h4>
                  <img
                    src={data?.wbCase2?.publicURL || ""}
                    alt="GraphQL APIと通常のWeb APIの比較の概念図"
                  />
                  <h5>通常の診断における課題</h5>
                  <p>
                    GraphQL を用いた Web API のセキュリティを確保するためには、
                    Broken Object Level Authorization といった Web API
                    において顕在化しやすい脆弱性だけではなく、Introspection
                    Query
                    によるスキーマ情報の流出や不正に細工されたクエリによって発生する
                    DoS(Denial of Service)など、 GraphQL API
                    固有の脆弱性が存在しないことを確認する必要があります。
                    <br />
                    <br />
                    これらの脆弱性に関する正確な診断を行うためには、従来の Web
                    API (いわゆる REST API) のそれとは大きく異なる、GraphQL
                    固有のコンフィグレーションや実装をホワイトボックス的に精査する必要があります。そのため、本診断を行う診断員には
                    GraphQL API のエコシステムと Web API
                    セキュリティの双方に深い知見を有していることが求められます。
                  </p>
                  <h5>提供をご依頼するデータ</h5>
                  <p>
                    GraphQL を用いた Web API の診断を実施する際には、当該
                    GraphQL API
                    が扱うデータの型やクエリの種類が定義されたスキーマファイルの提供をご依頼します。
                    より詳細な診断をご希望される際には、上記に加えて GraphQL
                    のクエリを実際に処理する Resolver
                    の実装やバックエンドのデータベース等との連携を含めたシステム構成図をご提供いただきます。
                    これらの内容を専門の診断員が精査することで、診断対象の
                    GraphQL API に脆弱性が存在しないかを診断いたします。
                  </p>
                  <h5>類似ケース</h5>
                  <p>
                    Flatt Security では、GraphQL API のみの診断に限らず、GraphQL
                    API とデータをやり取りする Web
                    アプリケーションを含めた全体的な診断や、その他の Web API
                    の診断にも対応しております。対応可能な環境や、提供が必要なデータについてはお気軽にお問い合わせください。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="ホワイトボックス診断の事例インタビュー">
              <AssessmentVoiceList
                slugs={[
                  "goodpatch",
                  "soracom",
                  "freee",
                  "susten",
                  "glory",
                  "sbi_digitrust",
                  "kanmu",
                  "primenumber",
                ]}
              />
            </Interview>
          </LazyLoad>

          <Section background="brand">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>料金</h2>
                <h3>
                  ヒアリングとお見積もりを通して最終的な料金を決定させていただきます。
                </h3>
                <p>
                  通常のセキュリティ診断（ブラックボックス診断）ではリクエスト数
                  ×
                  リクエスト単価の計算式をベースにしたお見積もりを実施しておりますが、ホワイトボックス診断ではその特殊な性質上、診断内容と規模に応じた都度のお見積りを実施させていただきます。
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>

          <AssessmentDocContact />
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query WhiteboxPage {
    description: file(
      relativePath: { eq: "assessment/whitebox_description.png" }
    ) {
      publicURL
    }
    wbCase1: file(relativePath: { eq: "assessment/wb_case1.png" }) {
      publicURL
    }
    wbCase2: file(relativePath: { eq: "assessment/wb_case2.png" }) {
      publicURL
    }
  }
`;

export default withContext(Component);
